import { AsyncPipe, NgComponentOutlet } from '@angular/common';
import {
  Component,
  computed,
  DestroyRef,
  effect,
  inject,
  input,
  InputSignal,
  isDevMode,
  signal,
} from '@angular/core';
import {
  takeUntilDestroyed,
  toObservable,
  toSignal,
} from '@angular/core/rxjs-interop';
import { AbstractControl } from '@angular/forms';
import {
  filter,
  isObservable,
  Observable,
  of,
  startWith,
  switchMap,
} from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonComponent } from '@integral/shared/ui/core';

import { LamieStepperProgressComponent } from '../lamie-stepper-progress/lamie-stepper-progress.component';
import { LamieStepperPageBaseComponent } from './lamie-stepper-page-base.component';
import { LamieStepperService } from './lamie-stepper.service';

export interface StepInputs {
  stepControl?: AbstractControl;
  additionalInputs?: { [key: string]: string | undefined };
}

export interface StepperStep {
  componentClass: LamieStepperPage<LamieStepperPageBaseComponent>;
  componentInputs?: StepInputs;
}

export interface LamieStepperPage<T extends LamieStepperPageBaseComponent> {
  new (): T;
}

@Component({
  selector: 'lamie-stepper',
  standalone: true,
  template: `
    <div class="flex flex-col items-center w-full px-5 md:px-[20%] md:pt-[5%]">
      <!--      "@if" below to hide stepper on success/error page -->
      @if (index() != steps().length - 1) {
        <lamie-stepper-progress
          [stepsCount]="steps().length"
          [currentIndex]="index()"
          [stepTranslationText]="'stepper.step' | translate"
          [ofTranslationText]="'stepper.of' | translate"
        />
      }
      <ng-container
        [ngComponentOutlet]="currentStepClass()"
        [ngComponentOutletInputs]="{
          index: index(),
          stepControl: currentStepControl()
        }"
      ></ng-container>

      <div class="flex flex-row justify-between w-full">
        <span>
          @if (currentPage()?.showPrev() && hasPrev()) {
            <integral-button
              (click)="prev()"
              [text]="'stepper.buttonPrev' | translate"
              [buttonStyle]="'Secondary'"
            />
          }
        </span>
        <span>
          @if (
            endButtonIndex() === index() &&
            currentPage()?.showNext() &&
            hasNext()
          ) {
            <integral-button
              id="nextButton"
              (click)="next()"
              [text]="'stepper.endButton' | translate"
              [disabled]="!currentStepControlValid()"
            >
              <svg height="18" width="18" postfix class="fill-current ml-2">
                <use href="/assets/svg/chevron-right.svg#root" />
              </svg>
            </integral-button>
          } @else if (currentPage()?.showNext() && hasNext()) {
            <integral-button
              id="nextButton"
              (click)="next()"
              [text]="'stepper.buttonNext' | translate"
              [disabled]="!currentStepControlValid()"
            >
              <svg height="18" width="18" postfix class="fill-current ml-2">
                <use href="/assets/svg/chevron-right.svg#root" />
              </svg>
            </integral-button>
          }
        </span>
      </div>
    </div>
    @if (showDebugInfo) {
      endButtonIndex: {{ endButtonIndex() }} <br />
      index: {{ index() }} <br />
      hasNext: {{ hasNext() }} <br />
      currentStep: {{ currentStep() }} <br />
      this.currentStepControlValid:
      {{ currentStepControlValid() }}
    }
  `,
  imports: [
    NgComponentOutlet,
    AsyncPipe,
    TranslateModule,
    LamieStepperProgressComponent,
    ButtonComponent,
  ],
  providers: [LamieStepperService],
})
export class LamieStepperComponent {
  private readonly destroyRef = inject(DestroyRef);
  readonly stepperService = inject(LamieStepperService);

  protected showDebugInfo = isDevMode();

  protected readonly index = signal(0);
  endButtonIndex: InputSignal<number | undefined> = input();

  steps = input.required<StepperStep[]>();

  startIdx = input(0);
  startIdxEffect = effect(
    () => {
      this.index.set(this.startIdx());
    },
    { allowSignalWrites: true },
  );

  protected readonly currentStep = computed(() => {
    return this.steps()[this.index()];
  });

  protected readonly currentStepClass = computed(() => {
    return this.currentStep().componentClass;
  });

  protected readonly currentStepControl = computed(() => {
    return this.currentStep().componentInputs?.stepControl;
  });

  protected readonly currentStepControlStatus = toSignal(
    toObservable(this.currentStepControl).pipe(
      switchMap((currentStepControl) => {
        if (!currentStepControl) return of('VALID');
        return currentStepControl.statusChanges.pipe(
          startWith(currentStepControl.status),
        );
      }),
    ),
  );
  protected readonly currentStepControlValid = computed(() => {
    return this.currentStepControlStatus() === 'VALID';
  });

  protected currentPage = this.stepperService.currentPage;

  readonly hasNext = computed(() => {
    return this.index() + 1 < this.steps().length;
  });

  readonly hasPrev = computed(() => {
    return this.index() > 0;
  });

  next(): void {
    if (!this.hasNext()) return;
    this.triggerIndexChange((page) => page.onNext(), 1);
  }

  prev(): void {
    if (!this.hasPrev()) return;
    this.triggerIndexChange((page) => page.onPrev(), -1);
  }

  private triggerIndexChange(
    onChange: (
      page: LamieStepperPageBaseComponent,
    ) => Observable<boolean> | boolean,
    indexDelta: 1 | -1,
  ) {
    const curPage = this.currentPage();
    if (curPage == null) return;
    const onChangeReturn = onChange(curPage);
    if (isObservable(onChangeReturn)) {
      onChangeReturn
        .pipe(
          filter((doChange) => doChange),
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe(() => {
          this.updateIndex(indexDelta);
        });
    } else {
      if (onChangeReturn) {
        this.updateIndex(indexDelta);
      }
    }
  }

  private updateIndex(increment: number): void {
    this.index.update((curIdx) => curIdx + increment);
    console.log('updated stepidx', this.index(), this.currentStep());
  }
}
