import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { LamieStepperPageBaseComponent } from '@integral/common-components';
import {
  SelectBoxComponent,
  SelectBoxOption,
  TextareaComponent,
  TextInputComponent,
} from '@integral/shared/ui/form';
import { TranslateModule } from '@ngx-translate/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { distinct } from 'rxjs';
import { allCountries } from '../../assets/i18n/countries';
import { ErrorMessageComponent } from '@integral/shared/ui/layout';
import { NgClass } from '@angular/common';
import {
  dateNotElligibleValidator,
  dateNotExistingValidator,
  notInTheFutureValidator,
} from '@integral/shared/util';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  standalone: true,
  selector: 'app-page-3',
  imports: [
    ReactiveFormsModule,

    TranslateModule,
    NgClass,
    SelectBoxComponent,
    TextInputComponent,
    TextareaComponent,
    ErrorMessageComponent,
  ],
  template: `
    <form
      class="my-20 flex flex-col justify-between gap-5"
      [formGroup]="stepControl"
    >
      <h1>{{ 'page3.title' | translate }}</h1>
      <p>{{ 'page3.text' | translate }}</p>
      <h2 class="mt-10">{{ 'page3.claimLocation.heading' | translate }}</h2>
      <integral-text-input
        class="w-full lg:w-5/11"
        [cfg]="{
          name: 'city',
          label: 'page3.claimLocation.city.label' | translate,
          placeholder: 'page3.claimLocation.city.placeholder' | translate
        }"
      />
      <integral-select-box
        class="w-full lg:w-5/11"
        [cfg]="{
          name: 'country',
          label: 'page3.claimLocation.country.label' | translate,
          placeholder: 'page3.claimLocation.country.placeholder' | translate
        }"
        [options]="countries"
      />
      @if (
        stepControl.controls.country.errors?.['invalidValue'] &&
        stepControl.controls.country.dirty
      ) {
        <integral-error-message
          [title]="'errorMessages.sloveniaClaimLocation' | translate"
        ></integral-error-message>
      }
      <h2 class="mt-10">{{ 'page3.claimDate.heading' | translate }}</h2>
      <integral-text-input
        class="w-full lg:w-5/11"
        [cfg]="{
          name: 'claimDate',
          label: 'page3.claimDate.label' | translate,
          placeholder: 'page3.claimDate.placeholder' | translate
        }"
        [type]="'date'"
      />
      @if (
        stepControl.controls.claimDate.errors?.['dateInTheFuture'] &&
        stepControl.controls.claimDate.touched
      ) {
        <integral-error-message
          [title]="'errorMessages.claimDateError01' | translate"
        ></integral-error-message>
      } @else if (
        stepControl.controls.claimDate.errors?.['dateNotExisting'] &&
        stepControl.controls.claimDate.touched
      ) {
        <integral-error-message
          [title]="'errorMessages.claimDateError02' | translate"
        ></integral-error-message>
      } @else if (
        stepControl.controls.claimDate.errors?.['dateNotElligible'] &&
        stepControl.controls.claimDate.touched
      ) {
        <integral-error-message
          [title]="'errorMessages.claimDateError03' | translate"
        ></integral-error-message>
      }

      <h2 class="mt-10">
        {{ 'page3.lossEventDescription.heading' | translate }}
      </h2>
      <integral-textarea
        class="w-full lg:w-5/11"
        [cfg]="{
          name: 'lossEventDescription',
          label: 'page3.lossEventDescription.label' | translate,
          placeholder: 'page3.lossEventDescription.placeholder' | translate
        }"
      />
      <h2 class="mt-10">{{ 'page3.cost.heading' | translate }}</h2>
      <div
        formGroupName="estimatedCosts"
        class="w-full lg:w-5/11 flex justify-start items-end gap-2"
      >
        <integral-text-input
          class="w-4/6"
          [cfg]="{
            name: 'amount',
            label: 'page3.cost.label' | translate,
            placeholder: 'page3.cost.placeholder' | translate
          }"
          [type]="'number'"
        />
        <input
          class="appearance-none border border-gray-300 rounded py-2 px-3 text-gray-900 leading-tight w-1/6 currency"
          readonly
          placeholder="EUR"
        />
      </div>
    </form>
  `,
  styles: `
    :host {
      display: block;
      width: 100%;
    }
    .currency {
      height: fit-content;
    }
  `,
})
export class Page3Component
  extends LamieStepperPageBaseComponent
  implements OnInit
{
  private readonly destroyRef = inject(DestroyRef);
  @Input({ required: true }) override stepControl!: FormGroup<{
    city: FormControl<string>;
    country: FormControl<string>;
    claimDate: FormControl<string>;
    lossEventDescription: FormControl<string | undefined>;
    estimatedCosts: FormGroup<{
      currency: FormControl<string>;
      amount: FormControl<number>;
    }>;
  }>;

  readonly countries: SelectBoxOption[] = [];

  loadCountries() {
    Object.keys(allCountries).map((countryCode) =>
      this.countries.push({
        value: countryCode,
        label: 'countryNames.' + countryCode,
      }),
    );
  }

  override ngOnInit() {
    super.ngOnInit();
    this.loadCountries();
    const amountFC = this.stepControl.controls.estimatedCosts.controls.amount;
    if (!amountFC) {
      return;
    }
    amountFC.valueChanges
      .pipe(distinct(), takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => amountFC.setValue(+value, { emitEvent: false }));

    const formValue = this.stepControl.parent?.value;
    this.stepControl.controls.claimDate.setValidators([
      Validators.required,
      dateNotElligibleValidator(formValue.step1.claimEligibilityInfo.coverFrom),
      notInTheFutureValidator(),
      dateNotExistingValidator(),
    ]);
    this.stepControl.controls.claimDate.updateValueAndValidity();
  }
}
