import {
  computed,
  Directive,
  inject,
  Input,
  OnInit,
  signal,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { LamieStepperService } from './lamie-stepper.service';

@Directive({
  standalone: true,
})
export abstract class LamieStepperPageBaseComponent implements OnInit {
  private readonly stepperService = inject(LamieStepperService);

  @Input({ required: true }) index!: number;

  @Input() stepControl?: AbstractControl;

  protected _showNext = signal(true);
  protected _showPrev = signal(true);
  showNext = computed(() => this._showNext());
  showPrev = computed(() => this._showPrev());

  ngOnInit() {
    this.stepperService.registerPage(this);
  }

  onNext: () => Observable<boolean> | boolean = () => true;

  onPrev: () => Observable<boolean> | boolean = () => true;
}
