import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  ControlBaseAbstractDirective,
  formViewProvider,
} from './control-base-abstract.directive';
import { UpperCasePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'integral-file-upload',
  standalone: true,
  imports: [ReactiveFormsModule, TranslateModule, UpperCasePipe],
  template: ` <div
    class="w-full lg:w-5/11 border border-dashed rounded p-5 my-5 flex flex-col items-center justify-center"
    (dragenter)="$event.preventDefault(); $event.stopPropagation()"
    (drop)="$event.preventDefault(); $event.stopPropagation(); onDrop($event)"
    (dragover)="$event.preventDefault(); $event.stopPropagation()"
  >
    <div class="flex justify-center">
      <svg width="27" height="32" class="fill-current text-gray-500">
        <use href="/assets/svg/file.svg#root" />
      </svg>
    </div>
    <div class="py-2 flex justify-center flex-wrap items-center">
      <label
        class="inline-block text-blue-700 cursor-pointer text-center w-full"
      >
        {{ cfg.label }}
        <input
          class="invisible w-0 h-0"
          type="file"
          [formControlName]="cfg.name"
          (change)="onFileSelected($event)"
          accept="{{ allowedExtensions }}"
        />
      </label>
      <span class="text-gray-500 text-center w-full">
        {{ 'uploadArea.dragDropInstruction' | translate }}</span
      >
    </div>
    <div
      class="text-gray-500 flex flex-col justify-center item-center gap-2 flex-wrap"
    >
      <span class="text-center w-full">{{
        formatedExtensionsInput | uppercase
      }}</span>
      <span class="text-center w-full">{{
        'uploadArea.uploadSize' | translate
      }}</span>
    </div>
    <p class="py-2 text-center w-full">
      {{ 'uploadArea.chosenFileInfo' | translate }}
      {{ uploadedFileName || 'uploadArea.noChosenFileInfo' | translate }}
    </p>
  </div>`,
  styles: `
    :host {
      display: block;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [formViewProvider], // needed so this can be registered to a parent form
})
export class FileUploadComponent extends ControlBaseAbstractDirective {
  @Input() allowedExtensions = '.pdf,.png,.jpg,.jpeg,.gif';
  @Input() uploadedFileName: string | undefined = undefined;
  @Output() fileSelected = new EventEmitter<Event>();
  @Output() fileDropped = new EventEmitter<Event>();
  protected readonly formatedExtensionsInput: string = '';

  constructor() {
    super();
    this.formatedExtensionsInput = this.allowedExtensions.replace(/[.]/g, ' ');
  }

  onFileSelected(event: Event) {
    this.fileSelected.emit(event);
  }
  onDrop(event: Event) {
    this.fileDropped.emit(event);
  }
}
