import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { LamieStepperPageBaseComponent } from '@integral/common-components';
import {
  CheckboxComponent,
  TextInputComponent,
} from '@integral/shared/ui/form';
import {
  catchError,
  distinctUntilChanged,
  finalize,
  map,
  Observable,
  of,
  tap,
} from 'rxjs';

import {
  ClaimApi,
  ClaimApiHeadersService,
} from '@integral/shared/backends/claim';
import {
  ErrorMessageComponent,
  LoadingOverlayStateService,
} from '@integral/shared/ui/layout';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IbanFormatterPipe } from '@integral/shared/util';

@Component({
  selector: 'app-page-7',
  standalone: true,
  providers: [IbanFormatterPipe],
  imports: [
    ReactiveFormsModule,
    TranslateModule,
    TextInputComponent,
    CheckboxComponent,
    ErrorMessageComponent,
  ],
  template: ` <form
    class="my-20 flex flex-col justify-between gap-5"
    [formGroup]="stepControl"
  >
    <h1>{{ 'page7.title' | translate }}</h1>
    <p>{{ 'page7.text' | translate }}</p>

    <div class="w-full lg:w-5/11 flex flex-col gap-6 mt-6">
      <integral-text-input
        [class.lessOpacity]="stepControl.controls.bankAccountHolder.disabled"
        class="w-full"
        [cfg]="{
          name: 'bankAccountHolder',
          label: 'page7.bankAccountHolder.label' | translate,
          placeholder: 'page7.bankAccountHolder.placeholder' | translate
        }"
      />
      <integral-text-input
        [class.lessOpacity]="stepControl.controls.iban.disabled"
        class="w-full"
        [cfg]="{
          name: 'iban',
          label: 'page7.iban.label' | translate,
          placeholder: 'page7.iban.placeholder' | translate
        }"
      />
    </div>
    @if (
      stepControl.controls.iban.touched &&
      stepControl.controls.iban.dirty &&
      stepControl.controls.iban.errors?.['iban']
    ) {
      <integral-error-message
        class="w-full"
        [title]="'errorMessages.ibanError01' | translate"
      ></integral-error-message>
    } @else if (
      stepControl.controls.iban.touched &&
      stepControl.controls.iban.dirty &&
      stepControl.controls.iban.errors?.['invalidSlovenianIban']
    ) {
      <integral-error-message
        class="w-full"
        [title]="'errorMessages.ibanError02' | translate"
      ></integral-error-message>
    }
    <div class="w-full flex flex-col gap-6 ">
      <integral-checkbox
        class="w-full"
        [cfg]="{
          name: 'noBankDataConsent',
          label: 'page7.consent.noBankDataConsent' | translate
        }"
      />
    </div>

    <hr class="my-6" />

    <h2>{{ 'page7.heading' | translate }}</h2>
    <div class="w-full flex flex-col gap-6">
      <integral-checkbox
        class="w-full"
        [cfg]="{
          name: 'consentRoamingOk',
          label: 'page7.consent.consentRoamingOk' | translate
        }"
      />
      <integral-checkbox
        class="w-full"
        [cfg]="{
          name: 'consentTBD',
          label: 'page7.consent.consentMoneyTransfer' | translate
        }"
      />
      <input type="hidden" [formControlName]="'claimNumber'" />
    </div>
  </form>`,
  styles: `
    :host {
      display: block;
      width: 100%;
    }
    .lessOpacity {
      opacity: 0.5;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Page7Component
  extends LamieStepperPageBaseComponent
  implements OnInit
{
  private readonly claimAggregationApiService = inject(
    ClaimApi.ClaimAggregationApiService,
  );
  private readonly claimApiHeadersService = inject(ClaimApiHeadersService);
  private readonly loaderService = inject(LoadingOverlayStateService);
  private readonly destroyRef = inject(DestroyRef);
  private readonly ibanFormatter = inject(IbanFormatterPipe);

  @Input({ required: true }) override stepControl!: FormGroup<{
    bankAccountHolder: FormControl<string>;
    iban: FormControl<string>;
    noBankDataConsent: FormControl<boolean>;
    consentRoamingOk: FormControl<boolean>;
    consentTBD: FormControl<boolean>;
    claimNumber: FormControl<string | undefined | null>;
  }>;

  override ngOnInit() {
    super.ngOnInit();
    const ibanFC = this.stepControl.controls.iban;
    ibanFC.valueChanges
      .pipe(distinctUntilChanged(), takeUntilDestroyed(this.destroyRef))
      .subscribe((iban) => {
        ibanFC.setValue(this.ibanFormatter.transform(iban), {
          emitEvent: false,
        });
      });

    const noBankDataConsentFC = this.stepControl.controls.noBankDataConsent;
    noBankDataConsentFC.valueChanges
      .pipe(
        tap((value) => {
          if (value) {
            this.stepControl.controls.iban.reset();
            this.stepControl.controls.bankAccountHolder.reset();
            this.stepControl.controls.iban.disable();
            this.stepControl.controls.bankAccountHolder.disable();
          } else {
            this.stepControl.controls.iban.enable();
            this.stepControl.controls.bankAccountHolder.enable();
          }
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  override onNext = (): Observable<boolean> | boolean => {
    this.loaderService.showGlobalLoading();
    if (this.stepControl.invalid) {
      this._showNext.set(false);
    }

    const formValue = this.stepControl.parent?.value;
    const claimRequest: ClaimApi.CreateClaimRequestDto = {
      claimAffectedPerson: formValue.step6
        .claimAffectedPerson as ClaimApi.ClaimAffectedPersonDto,
      contractHolder: formValue.step6
        .contractHolder as ClaimApi.ContractHolderDto,
      customerIdentifier: formValue.step1.customerIdentifier,
      insuranceContractType:
        formValue.step1.claimEligibilityInfo.insuranceContractType,
      contractCoverFrom: formValue.step1.claimEligibilityInfo.coverFrom,
      additional: JSON.stringify({
        city: formValue.step3.city,
        country: formValue.step3.country,
      }),
      claimDate: formValue.step3.claimDate,
      lossEvent: formValue.step2.lossEvent,
      lossEventDescription: formValue.step3.lossEventDescription,
      estimatedCosts: {
        currency: formValue.step3.estimatedCosts.currency,
        amount: formValue.step3.estimatedCosts.amount,
      },
      payout: {
        details: JSON.stringify({
          iban: formValue.step7.iban,
          bankAccountHolder: formValue.step7.bankAccountHolder,
        }),
        type: 'Bank',
      },
    };
    const requestParams = {
      ...this.claimApiHeadersService.defaultHeaders,
      lamieToken: formValue.step1.token,
      createClaimRequestDto: claimRequest,
    };
    return this.claimAggregationApiService.create(requestParams).pipe(
      tap((response) => {
        if (response.claimNumber) {
          this.stepControl.parent?.reset();
        }
        this.stepControl.controls.claimNumber.setValue(response.claimNumber);
      }),
      map(() => true),
      catchError((e) => {
        console.log('claim error', e);
        this.loaderService.hideGlobalLoading();
        return of(true);
      }),
      finalize(() => this.loaderService.hideGlobalLoading()),
    );
  };
}
