import { ApplicationConfig, importProvidersFrom } from '@angular/core';

import {
  AUTH_API_BASE_URL,
  httpAuthInterceptor,
} from '@integral/a1-freemium/data-access';
import { environment } from '../environments/environment';
import {
  HttpClient,
  provideHttpClient,
  withInterceptors,
} from '@angular/common/http';
import {
  CLAIM_API_HEADERS_STATIC,
  ClaimApi,
} from '@integral/shared/backends/claim';
import {
  FILE_API_HEADERS_STATIC,
  FileApi,
} from '@integral/shared/backends/file';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      TranslateModule.forRoot({
        defaultLanguage: 'en',
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      }),
    ),
    provideHttpClient(withInterceptors([httpAuthInterceptor])),
    {
      provide: AUTH_API_BASE_URL,
      useValue: environment.authApi.baseUrl,
    },
    {
      provide: ClaimApi.Configuration,
      // useFactory: (authService: AuthService) =>
      //   new A1FreemiumApi.Configuration({
      //     basePath: environment.claimApi.baseUrl,
      //     accessToken: authService.getAccessToken.bind(authService),
      //   }),
      // deps: [AuthService],
      useValue: new ClaimApi.Configuration({
        basePath: environment.claimApi.baseUrl,
      }),
    },
    {
      provide: CLAIM_API_HEADERS_STATIC,
      useValue: environment.claimApi.staticHeaders,
    },
    {
      provide: FileApi.Configuration,
      // useFactory: (authService: AuthService) =>
      //   new A1FreemiumApi.Configuration({
      //     basePath: environment.claimApi.baseUrl,
      //     accessToken: authService.getAccessToken.bind(authService),
      //   }),
      // deps: [AuthService],
      useValue: new FileApi.Configuration({
        basePath: environment.fileApi.baseUrl,
      }),
    },
    {
      provide: FILE_API_HEADERS_STATIC,
      useValue: environment.fileApi.staticHeaders,
    },
  ],
};
