import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  OnInit,
  signal,
} from '@angular/core';
import { LamieStepperPageBaseComponent } from '@integral/common-components';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import {
  SelectBoxComponent,
  TextInputComponent,
} from '@integral/shared/ui/form';
import {
  ClaimApi,
  ClaimApiHeadersService,
} from '@integral/shared/backends/claim';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { catchError, finalize, of, Subject, switchMap, tap } from 'rxjs';
import {
  ErrorMessageComponent,
  LoadingOverlayStateService,
} from '@integral/shared/ui/layout';

@Component({
  selector: 'app-page-6',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TranslateModule,
    SelectBoxComponent,
    TextInputComponent,
    ErrorMessageComponent,
  ],
  template: ` <form
    class="my-20 flex flex-col justify-between gap-5"
    [formGroup]="stepControl"
  >
    <ng-container formGroupName="claimAffectedPerson">
      <h1>{{ 'page6.title' | translate }}</h1>
      <p>{{ 'page6.text' | translate }}</p>
      <h2 class="mt-10">
        {{ 'page6.personalInformation.heading' | translate }}
      </h2>
      <div class="flex flex-wrap justify-between gap-4 w-full">
        <div class="w-full lg:w-5/11 flex flex-col gap-6">
          <integral-select-box
            class="w-full"
            [cfg]="{
              name: 'gender',
              label: 'page6.personalInformation.gender.label' | translate,
              placeholder:
                'page6.personalInformation.gender.placeholder' | translate
            }"
            [options]="genders"
          />
          <integral-text-input
            class="w-full"
            [cfg]="{
              name: 'firstName',
              label: 'page6.personalInformation.firstName.label' | translate,
              placeholder:
                'page6.personalInformation.firstName.placeholder' | translate
            }"
            [readonly]="isReadonly()"
          />
        </div>
        <div class="flex flex-col justify-end w-full lg:w-5/11">
          <integral-text-input
            class="w-full"
            [cfg]="{
              name: 'lastName',
              label: 'page6.personalInformation.lastName.label' | translate,
              placeholder:
                'page6.personalInformation.lastName.placeholder' | translate
            }"
            [readonly]="isReadonly()"
          />
        </div>
      </div>

      <h2 class="mt-10">
        {{ 'page6.addressInformation.heading' | translate }}
      </h2>

      <div class="w-full flex flex-col gap-6">
        <div class="w-full">
          <integral-text-input
            class="w-full"
            [cfg]="{
              name: 'addressLine1',
              label: 'page6.addressInformation.addressLine1.label' | translate,
              placeholder:
                'page6.addressInformation.addressLine1.placeholder' | translate
            }"
          />
        </div>

        <div class="w-full flex flex-wrap justify-between gap-4 ">
          <integral-text-input
            class="w-full lg:w-5/11"
            [cfg]="{
              name: 'postalCode',
              label: 'page6.addressInformation.postalCode.label' | translate,
              placeholder:
                'page6.addressInformation.postalCode.placeholder' | translate
            }"
          />
          <integral-text-input
            class="w-full lg:w-5/11"
            [cfg]="{
              name: 'city',
              label: 'page6.addressInformation.city.label' | translate,
              placeholder:
                'page6.addressInformation.city.placeholder' | translate
            }"
          />
        </div>
      </div>

      <h2 class="mt-10">
        {{ 'page6.contactAndDateInformation.heading' | translate }}
      </h2>

      <div class="flex flex-wrap justify-between gap-4 w-full">
        <div class="w-full flex flex-wrap justify-between gap-4">
          <integral-text-input
            class="w-full lg:w-5/11"
            [cfg]="{
              name: 'dateOfBirth',
              label:
                'page6.contactAndDateInformation.dateOfBirth.label' | translate,
              placeholder:
                'page6.contactAndDateInformation.dateOfBirth.placeholder'
                | translate
            }"
            [type]="'date'"
            [readonly]="isReadonly()"
          />
          <integral-text-input
            class="w-full lg:w-5/11"
            [cfg]="{
              name: 'nationalIdentificationNumber',
              label:
                'page6.contactAndDateInformation.nationalIdentificationNumber.label'
                | translate,
              placeholder:
                'page6.contactAndDateInformation.nationalIdentificationNumber.placeholder'
                | translate
            }"
          />
        </div>

        <div class="w-full flex flex-wrap justify-between gap-4">
          <integral-text-input
            class="w-full lg:w-5/11"
            [cfg]="{
              name: 'email',
              label: 'page6.contactAndDateInformation.email.label' | translate,
              placeholder:
                'page6.contactAndDateInformation.email.placeholder' | translate
            }"
          />
          <integral-text-input
            class="w-full lg:w-5/11"
            [cfg]="{
              name: 'phoneNumber',
              label:
                'page6.contactAndDateInformation.phoneNumber.label' | translate,
              placeholder:
                'page6.contactAndDateInformation.phoneNumber.placeholder'
                | translate
            }"
          />
          <input type="hidden" [formControlName]="'type'" />
        </div>
        @if (
          stepControl.controls.claimAffectedPerson.controls.dateOfBirth
            .errors?.['dateInTheFuture'] &&
          stepControl.controls.claimAffectedPerson.controls.dateOfBirth.touched
        ) {
          <integral-error-message
            class="w-full"
            [title]="'errorMessages.claimDateError01' | translate"
          ></integral-error-message>
        } @else if (
          stepControl.controls.claimAffectedPerson.controls.dateOfBirth
            .errors?.['dateNotExisting'] &&
          stepControl.controls.claimAffectedPerson.controls.dateOfBirth.touched
        ) {
          <integral-error-message
            class="w-full"
            [title]="'errorMessages.claimDateError02' | translate"
          ></integral-error-message>
        } @else if (
          stepControl.controls.claimAffectedPerson.controls.email.errors?.[
            'email'
          ] && stepControl.controls.claimAffectedPerson.controls.email.touched
        ) {
          <integral-error-message
            class="w-full"
            [title]="'errorMessages.emailError' | translate"
          ></integral-error-message>
        }
      </div>
    </ng-container>
    <input type="hidden" [formControlName]="'contractHolder'" />
  </form>`,
  styles: `
    :host {
      display: block;
      width: 100%;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Page6Component
  extends LamieStepperPageBaseComponent
  implements OnInit
{
  private readonly claimAggregationApiService = inject(
    ClaimApi.ClaimAggregationApiService,
  );
  private readonly claimApiHeadersService = inject(ClaimApiHeadersService);
  private readonly loaderService = inject(LoadingOverlayStateService);

  @Input({ required: true }) override stepControl!: FormGroup<{
    claimAffectedPerson: FormGroup<{
      gender: FormControl<string | undefined>;
      firstName: FormControl<string | null>;
      lastName: FormControl<string | null>;
      addressLine1: FormControl<string | null>;
      postalCode: FormControl<string | null>;
      city: FormControl<string | null>;
      dateOfBirth: FormControl<string | null>;
      nationalIdentificationNumber: FormControl<string | null>;
      email: FormControl<string>;
      phoneNumber: FormControl<string>;
      type: FormControl<ClaimApi.ClaimAffectedPersonType>;
    }>;
    contractHolder: FormControl<ClaimApi.ContractHolderDto | undefined>;
  }>;

  protected readonly genders = Object.keys(ClaimApi.Gender).map((key) => {
    return {
      value: key,
      label: `page6.personalInformation.gender.${key.toLowerCase()}`,
    };
  });

  protected readonly isReadonly = signal(false);

  private readonly componentInitialised = new Subject<void>();

  private personalDataSubscription = this.componentInitialised
    .pipe(
      tap(() => this.loaderService.showGlobalLoading()),
      switchMap(() => {
        const formValue = this.stepControl.parent?.value;
        const requestParams = {
          ...this.claimApiHeadersService.defaultHeaders,
          lamieToken: formValue.step1.token,
          customerIdentifier: formValue.step1.customerIdentifier,
        };

        return this.claimAggregationApiService
          .getAffectedPersonInfo(requestParams)
          .pipe(
            tap((response) => {
              if (
                response.affectedPerson &&
                response.affectedPersonType ===
                  ClaimApi.ClaimAffectedPersonType.ContractHolder
              ) {
                this.stepControl.controls.claimAffectedPerson.patchValue(
                  response.affectedPerson,
                );
                this.isReadonly.set(true);
              }
              if (response.contractHolder) {
                this.stepControl.controls.contractHolder.setValue(
                  response.contractHolder,
                );
              }
              if (response.affectedPersonType) {
                this.stepControl.controls.claimAffectedPerson.controls.type.setValue(
                  response.affectedPersonType,
                );
              }
            }),
            catchError((e) => {
              console.log('getAffectedPersonInfo error', e);
              return of('no valid persons found');
            }),
            finalize(() => this.loaderService.hideGlobalLoading()),
          );
      }),
    )
    .pipe(takeUntilDestroyed())
    .subscribe();

  override ngOnInit() {
    super.ngOnInit();
    this.componentInitialised.next();
  }
}
