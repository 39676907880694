import { Component, inject } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TitleCasePipe, UpperCasePipe } from '@angular/common';
import { PageHeaderComponent } from '@integral/shared/ui/layout';


@Component({
  selector: 'lamie-header',
  standalone: true,
  template: `
    <integral-page-header secondLanguage="slv" jsonRefSecondLanguage="header.lang.slv"  jsonRefEnglish="header.lang.en" logoSrc="a1-logo.jpg" productLogoSrc="lamie-logo.png" [currentLanguage]="language" (newLang)="langChange($event)"></integral-page-header>
  `,
  imports: [
    TranslateModule,
    UpperCasePipe,
    TitleCasePipe,
    PageHeaderComponent
  ]
})
export class LamieHeaderComponent {
  private translate = inject(TranslateService);
  protected language = this.translate.getDefaultLang();

  langChange(event: string) {
    this.language = event;
    this.changeLanguage(this.language)
  }
  changeLanguage(newLang: string): void {
    this.translate.use(newLang);
  }

}
