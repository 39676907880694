import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  Input,
  OnInit,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { LamieStepperPageBaseComponent } from '@integral/common-components';
import { FileApi, FileApiHeadersService } from '@integral/shared/backends/file';
import { ButtonComponent } from '@integral/shared/ui/core';
import { filterNullish } from '@integral/shared/util';
import { TranslateModule } from '@ngx-translate/core';

import { BehaviorSubject, distinct, finalize, of, Subject } from 'rxjs';
import { catchError, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { Step1Form } from './page1.component';
import {
  FileUploadComponent,
  SelectBoxComponent,
  SelectBoxOption,
  TextInputComponent,
} from '@integral/shared/ui/form';
import { UplodedFileDetails } from '@integral/shared/backends/claim';
import { AsyncPipe, NgClass } from '@angular/common';
import {
  ErrorMessageComponent,
  LoadingBarComponent,
} from '@integral/shared/ui/layout';
import { allInvoiceTypes } from '../../assets/i18n/invoices-types';

@Component({
  selector: 'app-page4',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TranslateModule,
    ButtonComponent,
    SelectBoxComponent,
    TextInputComponent,
    NgClass,
    AsyncPipe,
    LoadingBarComponent,
    FileUploadComponent,
    ErrorMessageComponent,
  ],
  template: `
    <form
      class="my-20 flex flex-col justify-between gap-5"
      [formGroup]="stepControl"
    >
      <h1>{{ 'page4.title' | translate }}</h1>
      <p>{{ 'page4.text' | translate }}</p>

      <integral-file-upload
        [cfg]="{
          name: 'upload',
          label: 'uploadArea.uploadInstruction' | translate
        }"
        [allowedExtensions]="allowedExtensions"
        [uploadedFileName]="currentFile.value?.name"
        (fileSelected)="onFileSelected($event)"
        (fileDropped)="onDrop($event)"
      ></integral-file-upload>

      <div
        class="flex flex-wrap justify-between gap-4 w-full"
        formGroupName="fileInfos"
        [class.lessOpacity]="
          stepControl.controls.fileInfos.disabled || stepControl.valid
        "
      >
        <div class="w-full lg:w-5/11 flex flex-col gap-6">
          <integral-text-input
            class="w-full"
            [cfg]="{
              name: 'InvoiceDate',
              label: 'page4.invoiceInformation.invoiceDate.label' | translate,
              placeholder:
                'page4.invoiceInformation.invoiceDate.placeholder' | translate
            }"
            [type]="'date'"
          />
          @if (
            stepControl.controls.fileInfos.controls.InvoiceDate.errors?.[
              'dateInTheFuture'
            ] && stepControl.controls.fileInfos.controls.InvoiceDate.touched
          ) {
            <integral-error-message
              [title]="'errorMessages.claimDateError01' | translate"
            ></integral-error-message>
          } @else if (
            stepControl.controls.fileInfos.controls.InvoiceDate.errors?.[
              'dateNotExisting'
            ] && stepControl.controls.fileInfos.controls.InvoiceDate.touched
          ) {
            <integral-error-message
              [title]="'errorMessages.claimDateError02' | translate"
            ></integral-error-message>
          }

          <integral-text-input
            class="w-full"
            [cfg]="{
              name: 'Amount',
              label: 'page4.invoiceInformation.invoiceAmount.label' | translate,
              placeholder:
                'page4.invoiceInformation.invoiceAmount.placeholder' | translate
            }"
          />
          <integral-select-box
            class="w-full"
            [cfg]="{
              name: 'Currency',
              label:
                'page4.invoiceInformation.invoiceCurrency.label' | translate,
              placeholder:
                'page4.invoiceInformation.invoiceCurrency.placeholder'
                | translate
            }"
            [options]="currencies"
          />
          <integral-select-box
            class="w-full"
            [cfg]="{
              name: 'InvoiceType',
              label: 'page4.invoiceInformation.invoiceType.label' | translate,
              placeholder:
                'page4.invoiceInformation.invoiceType.placeholder' | translate
            }"
            [options]="invoiceTypes"
          />
        </div>
      </div>

      <integral-button
        [type]="'submit'"
        [text]="'page4.uploadButtonLabel' | translate"
        [disabled]="
          this.loading() ||
          stepControl.controls.fileInfos.disabled ||
          stepControl.controls.fileInfos.invalid
        "
        (click)="onClickUpload($event)"
      />

      <input type="hidden" [formControlName]="'uploadedFiles'" />

      @for (uploadedFile of uploadedFiles(); track $index) {
        <div
          class="flex flex-nowrap justify-between w-full lg:w-5/11 border border-dashed rounded p-5 my-5"
        >
          <div class=" w-5/6">
            <p class="flex flex-wrap justify-start w-5/6 wordBreak">
              {{ uploadedFile.fileName }}
            </p>

            <p class="flex flex-wrap justify-start w-5/6 wordBreak">
              {{ uploadedFile.fileInfos.InvoiceType }}
            </p>
          </div>
          <div class="w-1/6 flex justify-end">
            <button (click)="removeFile($index, $event)">
              <svg width="16" height="16">
                <use href="/assets/svg/remove-x.svg#root" />
              </svg>
            </button>
          </div>
        </div>
      }
      <div class="w-full lg:w-5/11" [class.hidden]="!loading()">
        <p class="w-full text-center">
          {{ 'loadingFile' | translate }}
        </p>
        <integral-loading-bar />
      </div>
      <div class="w-full lg:w-5/11" [class.hidden]="!removing()">
        <p class="w-full text-center">
          {{ 'removingFile' | translate }}
        </p>
        <integral-loading-bar [color]="'red'" />
      </div>
    </form>
  `,
  styles: `
    :host {
      display: block;
      width: 100%;
    }
    input[type='file'] {
      display: none;
    }
    .lessOpacity {
      opacity: 0.5;
    }
    .wordBreak {
      word-break: break-all;
    }
    /* TODO check how to unable tw class variant */
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Page4Component
  extends LamieStepperPageBaseComponent
  implements OnInit
{
  private readonly fileAggregationApiService = inject(
    FileApi.FileAggregationApiService,
  );
  private readonly fileApiHeadersService = inject(FileApiHeadersService);
  private readonly destroyRef = inject(DestroyRef);

  private readonly uploadClicked = new Subject<void>();
  protected readonly currentFile = new BehaviorSubject<File | undefined>(
    undefined,
  );

  private readonly removedClicked = new Subject<UplodedFileDetails>();

  private readonly removeFile$ = this.removedClicked.pipe(
    tap(() => {
      this.removing.set(true);
    }),
    switchMap((file) => {
      const lamieToken = this.getToken();
      const removeChunkRequestParams = {
        ...this.fileApiHeadersService.defaultHeaders,
        fileName: file.fileName,
        chunkIndex: 0,
        lamieToken,
        body: file.fileInfos,
      };
      return this.fileAggregationApiService
        .removeUpload(removeChunkRequestParams)
        .pipe(
          tap(() => {
            this.removing.set(false);
          }),
          catchError((e) => {
            //TODO
            console.log('show error popup remove', e);
            this.removing.set(false);
            return of('ok');
          }),
        );
    }),
  );

  private readonly uploadChunk$ = this.uploadClicked.pipe(
    tap(() => {
      this.loading.set(true);
    }),
    withLatestFrom(this.currentFile.pipe(filterNullish())),
    switchMap(([, file]) => {
      const lamieToken = this.getToken();

      const uploadChunkRequestParams: FileApi.UploadChunkRequestParams = {
        ...this.fileApiHeadersService.defaultHeaders,
        fileName: file.name,
        chunkIndex: 0,
        lamieToken,
        fileChunk: file,
      };
      return this.fileAggregationApiService
        .uploadChunk(uploadChunkRequestParams)
        .pipe(
          tap(() => {
            const uploadedFile: UplodedFileDetails = {
              fileName: file.name,
              fileInfos: this.stepControl.controls.fileInfos.getRawValue(),
            };
            const allFiles = this.stepControl.controls.uploadedFiles.value;
            this.stepControl.controls.uploadedFiles.setValue([
              ...allFiles,
              uploadedFile,
            ]);
            this.loading.set(false);
          }),
          catchError((e) => {
            console.log('show error popup', e); //TODO
            this.loading.set(false);
            return of('ok');
          }),
          switchMap(() => {
            const formValue = this.stepControl.controls.fileInfos.value;
            const finalizeRequestParams: FileApi.FinalizeUploadRequestParams = {
              ...this.fileApiHeadersService.defaultHeaders,
              fileName: file.name,
              totalChunks: 1,
              lamieToken,
              requestBody: formValue,
            };
            return this.fileAggregationApiService
              .finalizeUpload(finalizeRequestParams)
              .pipe(
                tap(() => {
                  this.stepControl.controls.fileInfos.reset();
                  this.stepControl.controls.upload.reset();
                  this.stepControl.controls.fileInfos.disable();
                }),
                catchError((e) => {
                  //TODO
                  console.log('show error popup error', e);
                  return of('ok');
                }),
                finalize(() => {
                  this.actionsOnError();
                }),
              );
          }),
        );
    }),
  );

  private getToken() {
    const step1Ctrl = this.stepControl.parent?.get('step1');
    if (!step1Ctrl) throw new Error('Ctrl for step1 unavailable');
    const step1FG = step1Ctrl as Step1Form;
    const lamieToken = step1FG.controls.token.value;
    if (!lamieToken) {
      this.actionsOnError();
      throw new Error('No token');
    }
    return lamieToken;
  }

  private actionsOnError() {
    this.loading.set(false);
    this.stepControl.controls.upload.reset();
    this.currentFile.next(undefined);
  }

  @Input({ required: true }) override stepControl!: FormGroup<{
    upload: FormControl<File | undefined>;
    uploadedFiles: FormControl<UplodedFileDetails[]>;
    fileInfos: FormGroup<{
      InvoiceDate: FormControl<string>;
      Amount: FormControl<string>;
      Currency: FormControl<string>;
      InvoiceType: FormControl<string>;
    }>;
  }>;

  protected readonly allowedExtensions = '.pdf,.png,.jpg,.jpeg,.gif';

  readonly currencies: SelectBoxOption[] = [
    { value: 'EUR', label: 'EUR' },
    /* { value: 'USD', label: 'USD' } TODO check currency list with customer*/
  ];

  readonly invoiceTypes: SelectBoxOption[] = [];

  protected uploadedFiles = signal([] as UplodedFileDetails[]);
  protected loading = signal(false);
  protected removing = signal(false);
  constructor() {
    super();
    this.uploadChunk$.pipe(takeUntilDestroyed()).subscribe();
    this.removeFile$.pipe(takeUntilDestroyed()).subscribe();
  }
  override ngOnInit() {
    super.ngOnInit();
    this.loadInvoiceTypes();
    this.stepControl.controls.fileInfos.disable();

    const uploadedFilesFC = this.stepControl.controls.uploadedFiles;
    const uploadedFilesValue = this.stepControl.controls.uploadedFiles.value;
    this.uploadedFiles.set(uploadedFilesValue);
    uploadedFilesFC.valueChanges
      .pipe(distinct(), takeUntilDestroyed(this.destroyRef))
      .subscribe((files) => {
        this.uploadedFiles.set(files);
      });
  }

  loadInvoiceTypes() {
    Object.keys(allInvoiceTypes).map((invoiceKey) =>
      this.invoiceTypes.push({
        value: invoiceKey,
        label: 'invoiceTypes.' + invoiceKey,
      }),
    );
  }

  protected onClickUpload(e: Event): void {
    e.preventDefault();
    this.uploadClicked.next();
  }

  protected onDrop(e: Event): void {
    const dataTransfer = (e as DragEvent).dataTransfer;
    const files = dataTransfer?.files;
    if (!files) return;
    this.currentFile.next(files[0]);
    this.stepControl.controls.fileInfos.enable();
  }

  protected onFileSelected(event: Event): void {
    this.stepControl.controls.fileInfos.enable();
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;
    this.currentFile.next(files[0]);
  }

  removeFile(fileIndex: number, e: Event) {
    e.preventDefault();
    const filesList = this.stepControl.controls.uploadedFiles.value;
    this.removedClicked.next(filesList.splice(fileIndex, 1)[0]);
    this.stepControl.controls.uploadedFiles.setValue(filesList);
    this.currentFile.next(undefined);
  }
}
