import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'lamie-stepper-progress',
  standalone: true,
  imports: [NgClass],
  template: `
    <div class="w-full flex flex-wrap justify-around gap-4">
      <div class=" w-full flex flex-nowrap justify-center gap-4">
        @for (index of indices(); track $index) {
          <div
            class="w-4 h-4 rounded-full"
            [ngClass]="{
              'ring-4 ring-primary300': index === currentIndex(),
              'bg-primary': index <= currentIndex(),
              'bg-neutral200': index > currentIndex()
            }"
          ></div>
        }
      </div>
      <div
        class="w-full text-neutral-700 text-base font-normal font-serif leading-tight text-center"
      >
        {{ stepTranslationText() }} {{ currentPage() }}
        {{ ofTranslationText() }} {{ stepsCount() - endPage }}
        <!-- to avoid showing the endpage (success/error) in the total count of steps-->
      </div>
    </div>
  `,
  styles: `
    :host {
      display: block;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LamieStepperProgressComponent {
  stepsCount = input.required<number>();
  endPage = 1;

  currentIndex = input(0);
  currentPage = computed(() => this.currentIndex() + 1);

  stepTranslationText = input('Step');
  ofTranslationText = input('of');

  protected indices = computed(() =>
    Array.from(Array(this.stepsCount() - this.endPage), (_, i) => i),
  );
}
