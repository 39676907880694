import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { LoadingBarComponent } from './loading-bar.component';
import { LoadingOverlayStateService } from './loading-overlay-state.service';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'integral-loading-overlay',
  standalone: true,
  imports: [LoadingBarComponent, TranslateModule],
  providers: [LoadingOverlayStateService],
  template: `
    <div [class.hidden]="!loading()">
      <div
        class="absolute bg-white bg-opacity-80 z-10 min-h-full h-full w-full flex items-center justify-center"
      >
        <div
          class="flex flex-col items-center justify-center gap-10 w-full bg-white p-12 md:w-3/4 lg:1/2 blur-box"
        >
          <p class="text-center">{{ 'generalLoading' | translate }}</p>
          <integral-loading-bar
            color="black"
            class="w-full"
          ></integral-loading-bar>
        </div>
      </div>
    </div>
    <ng-content></ng-content>
  `,
  styles: `
    :host {
      display: block;
    }
    .blur-box {
      box-shadow: 0 0 10px 10px rgba(255, 255, 255, 0.9);
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingOverlayComponent {
  private readonly loaderService = inject(LoadingOverlayStateService);
  protected readonly loading = this.loaderService.loadingState;
}
