import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  inject,
  Input,
  OnInit,
  signal,
  ViewChild,
} from '@angular/core';
import { LamieStepperPageBaseComponent } from '@integral/common-components';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import {
  BehaviorSubject,
  distinct,
  finalize,
  Observable,
  of,
  Subject,
} from 'rxjs';
import { ButtonComponent } from '@integral/shared/ui/core';
import {
  FileUploadComponent,
  SelectBoxComponent,
  TextInputComponent,
} from '@integral/shared/ui/form';
import { AsyncPipe, NgClass } from '@angular/common';
import { LoadingBarComponent } from '@integral/shared/ui/layout';
import { FileApi, FileApiHeadersService } from '@integral/shared/backends/file';
import { catchError, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { filterNullish } from '@integral/shared/util';
import { Step1Form } from './page1.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-page-5',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TranslateModule,
    ButtonComponent,
    SelectBoxComponent,
    TextInputComponent,
    AsyncPipe,
    FileUploadComponent,
    LoadingBarComponent,
    NgClass,
  ],
  template: `
    <form
      class="my-20 flex flex-col justify-between gap-5"
      [formGroup]="stepControl"
    >
      <h1>{{ 'page5.title' | translate }}</h1>
      <p>{{ 'page5.text' | translate }}</p>

      <integral-file-upload
        [cfg]="{
          name: 'upload',
          label: 'uploadArea.uploadInstruction' | translate
        }"
        [allowedExtensions]="allowedExtensions"
        [uploadedFileName]="
          currentFile.value?.name || 'uploadArea.noChosenFileInfo' | translate
        "
        (fileSelected)="onFileSelected($event)"
        (fileDropped)="onDrop($event)"
      ></integral-file-upload>

      <integral-button
        [type]="'submit'"
        [text]="'page5.uploadButtonLabel' | translate"
        [disabled]="
          loading() || removing() || !stepControl.controls.upload.value
        "
        (click)="onClickUpload($event)"
      />

      @for (uploadedFile of uploadedFiles(); track $index) {
        <div
          class="flex flex-nowrap justify-between w-full lg:w-5/11 border border-dashed rounded p-5 my-5"
        >
          <div class=" w-5/6">
            <p class="flex flex-wrap justify-start w-5/6 wordBreak">
              {{ uploadedFile }}
            </p>
          </div>
          <div class="w-1/6 flex justify-end">
            <button (click)="removeFile($index, $event)">
              <svg width="16" height="16">
                <use href="/assets/svg/remove-x.svg#root" />
              </svg>
            </button>
          </div>
        </div>
      }
      <div class="w-full lg:w-5/11" [ngClass]="!loading() ? 'hidden' : ''">
        <p class="w-full text-center">
          {{ 'loadingFile' | translate }}
        </p>
        <integral-loading-bar />
      </div>
      <div class="w-full lg:w-5/11" [ngClass]="!removing() ? 'hidden' : ''">
        <p class="w-full text-center">
          {{ 'removingFile' | translate }}
        </p>
        <integral-loading-bar [color]="'red'" />
      </div>

      <input type="hidden" [formControlName]="'uploadedFiles'" />
    </form>

    <dialog #consentDialog class="p-5 rounded max-w-2xl">
      <div
        class="relative flex w-full max-w-screen-md px-4 py-4 text-base rounded-lg font-regular"
      >
        <span
          class="!absolute top-3 left-3 h-8 max-h-[32px] w-8 max-w-[32px] select-none rounded-lg text-center align-middle text-xs font-medium uppercase transition-all"
        >
          <span
            class="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 text-error600"
          >
            <svg width="30" height="30" class="fill-current text-error600">
              <use href="/assets/svg/info.svg#root" />
            </svg>
          </span>
        </span>
        <div class="ml-12 mr-12">
          <h2
            class="block text-xl antialiased font-semibold leading-snug tracking-normal font-serif"
          >
            {{ 'page5.dialog.title' | translate }}
          </h2>
          <p
            class="block mt-2 text-base antialiased font-normal leading-relaxed"
            [innerHTML]="'page5.dialog.text' | translate"
          ></p>
        </div>
      </div>
      <div class="flex flex-row-reverse mt-4">
        <integral-button
          class="ml-4"
          [text]="'page5.dialog.continueButton' | translate"
          (click)="onClickAccept()"
        >
          <svg height="18" width="18" postfix class="fill-current ml-2">
            <use href="/assets/svg/chevron-right.svg#root" />
          </svg>
        </integral-button>
      </div>
    </dialog>
  `,
  styles: `
    :host {
      display: block;
      width: 100%;
    }
    input[type='file'] {
      display: none;
    }
    .lessOpacity {
      opacity: 0.5;
    }
    /* TODO check how to unable tw class variant */
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Page5Component
  extends LamieStepperPageBaseComponent
  implements OnInit
{
  @Input({ required: true }) override stepControl!: FormGroup<{
    upload: FormControl<File | undefined>;
    uploadedFiles: FormControl<string[]>;
  }>;
  private readonly destroyRef = inject(DestroyRef);
  @ViewChild('consentDialog') consentDialog?: ElementRef<HTMLDialogElement>;

  private readonly dialogAccepted = new Subject<boolean>();

  private readonly fileAggregationApiService = inject(
    FileApi.FileAggregationApiService,
  );
  private readonly fileApiHeadersService = inject(FileApiHeadersService);

  private readonly uploadClicked = new Subject<void>();
  protected readonly currentFile = new BehaviorSubject<File | undefined>(
    undefined,
  );

  private readonly removedClicked = new Subject<string>();

  private readonly removeFile$ = this.removedClicked.pipe(
    tap(() => {
      this.removing.set(true);
    }),
    switchMap((file) => {
      const lamieToken = this.getToken();
      const removeChunkRequestParams = {
        ...this.fileApiHeadersService.defaultHeaders,
        fileName: file,
        chunkIndex: 0,
        lamieToken,
        body: {}, //TODO check if needed
      };
      return this.fileAggregationApiService
        .removeUpload(removeChunkRequestParams)
        .pipe(
          tap(() => {
            this.removing.set(false);
          }),
          catchError((e) => {
            //TODO
            console.log('show error popup remove', e);
            this.removing.set(false);
            return of('ok');
          }),
        );
    }),
  );

  private readonly uploadChunk$ = this.uploadClicked.pipe(
    tap(() => {
      this.loading.set(true);
    }),
    withLatestFrom(this.currentFile.pipe(filterNullish())),
    switchMap(([, file]) => {
      const lamieToken = this.getToken();

      const uploadChunkRequestParams: FileApi.UploadChunkRequestParams = {
        ...this.fileApiHeadersService.defaultHeaders,
        fileName: file.name,
        chunkIndex: 0,
        lamieToken,
        fileChunk: file,
      };
      return this.fileAggregationApiService
        .uploadChunk(uploadChunkRequestParams)
        .pipe(
          tap(() => {
            const uploadedFile = {
              fileName: file.name,
            };
            const allFiles = this.stepControl.controls.uploadedFiles.value;
            this.stepControl.controls.uploadedFiles.setValue([
              ...allFiles,
              uploadedFile.fileName,
            ]);
            this.loading.set(false);
          }),
          catchError((e) => {
            console.log('show error popup', e); //TODO
            this.loading.set(false);
            return of('ok');
          }),
          switchMap(() => {
            const finalizeRequestParams: FileApi.FinalizeUploadRequestParams = {
              ...this.fileApiHeadersService.defaultHeaders,
              fileName: file.name,
              totalChunks: 1,
              lamieToken,
              requestBody: {}, // TODO check if needed
            };
            return this.fileAggregationApiService
              .finalizeUpload(finalizeRequestParams)
              .pipe(
                catchError((e) => {
                  //TODO
                  console.log('show error popup error', e);
                  return of('ok');
                }),
                finalize(() => {
                  console.log('show error popup finalize');
                  this.actionsOnError();
                }),
              );
          }),
        );
    }),
  );

  protected uploadedFiles = signal([] as string[]);
  protected loading = signal(false);
  protected removing = signal(false);
  protected readonly allowedExtensions = '.pdf,.png,.jpg,.jpeg,.gif';
  constructor() {
    super();
    this.uploadChunk$.pipe(takeUntilDestroyed()).subscribe();
    this.removeFile$.pipe(takeUntilDestroyed()).subscribe();
  }

  override ngOnInit() {
    super.ngOnInit();
    const uploadedFilesFC = this.stepControl.controls.uploadedFiles;
    const uploadedFilesValue = this.stepControl.controls.uploadedFiles.value;
    this.uploadedFiles.set(uploadedFilesValue);
    uploadedFilesFC.valueChanges
      .pipe(distinct(), takeUntilDestroyed(this.destroyRef))
      .subscribe((files) => {
        this.uploadedFiles.set(files);
      });
  }

  private getToken() {
    const step1Ctrl = this.stepControl.parent?.get('step1');
    if (!step1Ctrl) throw new Error('Ctrl for step1 unavailable');
    const step1FG = step1Ctrl as Step1Form;
    const lamieToken = step1FG.controls.token.value;
    if (!lamieToken) {
      this.actionsOnError();
      throw new Error('No token');
    }
    return lamieToken;
  }

  private actionsOnError() {
    this.loading.set(false);
    this.stepControl.controls.upload.reset();
    this.currentFile.next(undefined);
  }

  protected onClickUpload(e: Event): void {
    e.preventDefault();
    this.uploadClicked.next();
  }
  protected onDrop(e: Event): void {
    const dataTransfer = (e as DragEvent).dataTransfer;
    const files = dataTransfer?.files;
    if (!files) return;
    this.currentFile.next(files[0]);
  }

  protected onFileSelected(event: Event): void {
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;
    this.currentFile.next(files[0]);
  }

  removeFile(fileIndex: number, e: Event) {
    e.preventDefault();
    const filesList = this.stepControl.controls.uploadedFiles.value;
    this.removedClicked.next(filesList.splice(fileIndex, 1)[0]);
    this.stepControl.controls.uploadedFiles.setValue(filesList);
    this.currentFile.next(undefined);
  }

  override onNext = (): Observable<boolean> | boolean => {
    this.consentDialog?.nativeElement.showModal();
    return this.dialogAccepted.asObservable();
  };

  protected onClickAccept() {
    this.dialogAccepted.next(true);
  }
}
